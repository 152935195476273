import { Slider, TextField } from '@mui/material'
import { Map, useMap } from '@vis.gl/react-google-maps'
import { mapSearch } from 'api'
import { useDebounce } from 'hooks/useDebounce'
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react'

const LA_LAT = 34.0838
const LA_LONG = -118.3828

export const MapSearch = () => {
  const map = useMap()
  const [[currentLat, currentLng], setCurrentLatLng] = useState([
    LA_LAT,
    LA_LONG,
  ])
  const [query, setQuery] = useState('')
  const [locationWeight, setLocationWeight] = useState(1)
  const [placeWeight, setPlaceWeight] = useState(1)
  const [locationDistanceWeight, setLocationDistanceWeight] = useState(0.1)
  const [locationSimilarityWeight, setLocationSimilarityWeight] = useState(0.9)
  const [placeDistanceWeight, setPlaceDistanceWeight] = useState(0.25)
  const [placeSimilarityWeight, setPlaceSimilarityWeight] = useState(0.75)
  const [results, setResults] = useState([])

  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (e) => {
      const val = e.target.value
      setQuery(val)
    },
    [],
  )

  const debouncedSearch = useDebounce(async () => {
    console.log('running search...')
    const { data } = await mapSearch({
      query,
      biasLatitude: currentLat,
      biasLongitude: currentLng,
      locationWeight,
      placeWeight,
      locationDistanceWeight,
      locationSimilarityWeight,
      placeDistanceWeight,
      placeSimilarityWeight,
    })
    setResults(data?.data?.results ?? [])
  })

  useEffect(() => {
    if (query && query.length >= 3) {
      debouncedSearch()
    }
  }, [
    debouncedSearch,
    query,
    currentLat,
    currentLng,
    locationWeight,
    placeWeight,
    locationDistanceWeight,
    locationSimilarityWeight,
    placeDistanceWeight,
    placeSimilarityWeight,
  ])

  return (
    <div>
      <Map
        style={{ width: '100%', height: '400px' }}
        defaultCenter={{ lat: currentLat, lng: currentLng }}
        defaultZoom={11}
        disableDefaultUI={true}
        onCenterChanged={() => {
          const center = map?.getCenter()
          setCurrentLatLng([center?.lat() ?? LA_LAT, center?.lng() ?? LA_LONG])
        }}
      />
      <div
        style={{
          background: 'lightgray',
          marginTop: '8px',
          padding: '8px',
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'space-between',
          fontSize: '0.8rem',
        }}
      >
        <div>Current Lat: {currentLat.toFixed(4)}</div>
        <div>Current Long: {currentLng.toFixed(4)}</div>
      </div>
      <div
        style={{
          marginTop: '16px',
          display: 'flex',
          flexFlow: 'row nowrap',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
            flex: 1,
            marginRight: '18px',
          }}
        >
          <p style={{ fontSize: '0.85rem' }}>
            Location Weight ({locationWeight.toFixed(2)})
          </p>
          <Slider
            value={locationWeight}
            onChange={(_e, v) => {
              const num = v as number
              setLocationWeight(num)
            }}
            min={0}
            max={1}
            step={0.01}
            size="small"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
            flex: 1,
          }}
        >
          <p style={{ fontSize: '0.85rem' }}>
            Place Weight {placeWeight.toFixed(2)}
          </p>
          <Slider
            value={placeWeight}
            onChange={(_e, v) => {
              const num = v as number
              setPlaceWeight(num)
            }}
            min={0}
            max={1}
            step={0.01}
            size="small"
          />
        </div>
      </div>
      <div
        style={{
          marginTop: '16px',
          display: 'flex',
          flexFlow: 'row nowrap',
          marginBottom: '8px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
            flex: 1,
            marginRight: '18px',
          }}
        >
          <p style={{ fontSize: '0.85rem' }}>
            Location Distance Weight ({locationDistanceWeight.toFixed(2)})
          </p>
          <Slider
            value={locationDistanceWeight}
            onChange={(_e, v) => {
              const num = v as number
              setLocationDistanceWeight(num)
              setLocationSimilarityWeight(1 - num)
            }}
            min={0}
            max={1}
            step={0.01}
            size="small"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
            flex: 1,
          }}
        >
          <p style={{ fontSize: '0.85rem' }}>
            Location Similarity Weight {locationSimilarityWeight.toFixed(2)}
          </p>
          <Slider
            value={locationSimilarityWeight}
            onChange={(_e, v) => {
              const num = v as number
              setLocationSimilarityWeight(num)
              setLocationDistanceWeight(1 - num)
            }}
            min={0}
            max={1}
            step={0.01}
            size="small"
          />
        </div>
      </div>
      <div
        style={{ marginTop: '16px', display: 'flex', flexFlow: 'row nowrap' }}
      >
        <div
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
            flex: 1,
            marginRight: '18px',
          }}
        >
          <p style={{ fontSize: '0.85rem' }}>
            Place Distance Weight ({placeDistanceWeight.toFixed(2)})
          </p>
          <Slider
            value={placeDistanceWeight}
            onChange={(_e, v) => {
              const num = v as number
              setPlaceDistanceWeight(num)
              setPlaceSimilarityWeight(1 - num)
            }}
            min={0}
            max={1}
            step={0.01}
            size="small"
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
            flex: 1,
          }}
        >
          <p style={{ fontSize: '0.85rem' }}>
            Place Similarity Weight ({placeSimilarityWeight.toFixed(2)})
          </p>
          <Slider
            value={placeSimilarityWeight}
            onChange={(_e, v) => {
              const num = v as number
              setPlaceSimilarityWeight(num)
              setPlaceDistanceWeight(1 - num)
            }}
            min={0}
            max={1}
            step={0.01}
            size="small"
          />
        </div>
      </div>
      <div style={{ marginTop: '16px', marginBottom: '18px' }}>
        <TextField
          style={{ width: '100%' }}
          label="Search..."
          variant="standard"
          onChange={handleSearchChange}
        />
      </div>
      {results.length ? (
        <div>
          {results.map((result: any) => {
            const entity = result.entity.type
            const id = result.entity[entity].id
            return (
              <div
                key={id}
                style={{
                  padding: '16px',
                  background: 'lightgray',
                  marginBottom: '4px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexFlow: 'row nowrap',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                  }}
                >
                  <p style={{ fontWeight: 700, lineHeight: 1, margin: 0 }}>
                    {result.title}
                  </p>
                  <p style={{ fontSize: '0.8rem', lineHeight: 1, margin: 0 }}>
                    {result.entity.type}
                  </p>
                </div>
                <p style={{ fontSize: '0.85rem', lineHeight: 1, margin: 0 }}>
                  {result.subtitle}
                </p>
              </div>
            )
          })}
        </div>
      ) : (
        <div
          style={{
            fontStyle: 'italic',
            textAlign: 'center',
            padding: '16px 8px',
            background: 'lightgray',
          }}
        >
          No results
        </div>
      )}
    </div>
  )
}
