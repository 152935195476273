import api from './base'

const getAsk = (askId: string) => api.get(`/ask/${askId}`)
const listAsks = () => api.get(`/ask`)
const postAsk = (
  body: string,
  location: string,
  latitude: number,
  longitude: number,
) => api.post('/ask', { body, location, latitude, longitude })
const getAskRecs = (askId: string) => api.get(`/ask/${askId}/recs`)
const postAskRec = (
  askId: string,
  placeId: number,
  tip?: string,
  guestId?: string,
) => api.post(`/ask/${askId}/rec`, { placeId, tip, guestId })

export { getAsk, listAsks, postAsk, getAskRecs, postAskRec }
