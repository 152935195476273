import api from './base'

const searchPlaces = (
  query: string,
  biasLatitude?: number,
  biasLongitude?: number,
  textSearch?: boolean,
) =>
  api.get(
    `/search/?query=${query}&entities=${
      textSearch ? 'place' : 'placeSuggestion'
    }&biasLatitude=${biasLatitude ?? ''}&biasLongitude=${biasLongitude ?? ''}`,
  )
const searchLocations = (query: string) =>
  api.get(`/search/?query=${query}&entities=location`)

interface IMapSearch {
  query: string
  biasLatitude: number
  biasLongitude: number
  locationWeight: number
  placeWeight: number
  locationDistanceWeight: number
  locationSimilarityWeight: number
  placeDistanceWeight: number
  placeSimilarityWeight: number
}
const mapSearch = (dto: IMapSearch) => api.get('/search/map', { params: dto })

export { searchPlaces, searchLocations, mapSearch }
