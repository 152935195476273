export interface IUserData {
  id: number
  firstName: string
  lastName: string
  name: string
  bio: string
  isVerified: boolean
  username: string
  pictureUrl: string
  website: string
  createdAt: string
  modifiedAt: string
  followerCount: number
  followingCount: number
  goodCount: number
  wantCount: number
  listCount: number
  tipCount: number
}

export default class User {
  id: number
  firstName: string
  lastName: string
  name: string
  bio: string
  isVerified: boolean
  username: string
  picture: string
  website?: URL
  createdAt: Date
  modifiedAt: Date
  followerCount: number
  followingCount: number
  goodCount: number
  wantCount: number
  listCount: number
  tipCount: number
  constructor({
    id,
    firstName,
    lastName,
    name,
    bio,
    isVerified,
    username,
    pictureUrl,
    website,
    createdAt,
    modifiedAt,
    followerCount,
    followingCount,
    goodCount,
    wantCount,
    listCount,
    tipCount,
  }: IUserData) {
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.name = name ?? `${firstName} ${lastName}`
    this.bio = bio
    this.isVerified = isVerified
    this.username = username
    this.picture = pictureUrl
    this.website = website
      ? new URL(website.startsWith('http') ? website : `https://${website}`)
      : undefined
    this.createdAt = new Date(createdAt)
    this.modifiedAt = new Date(modifiedAt)
    this.followerCount = followerCount
    this.followingCount = followingCount
    this.goodCount = goodCount
    this.wantCount = wantCount
    this.listCount = listCount
    this.tipCount = tipCount
  }
}
