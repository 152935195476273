import PInput, { type CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {
  type ChangeEvent,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react'
import styled from '@emotion/styled'
import { Button } from 'components/Button'
import { getCode, verifyCode } from 'api'
import { TextField } from 'components/TextField'
import UserContext from 'context/UserContext'
import User from 'models/user'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Mixpanel } from 'lib/mixpanel'

const BETA_ALLOWED_COUNTRIES = ['us', 'gb', 'co']

const Main = styled.div({
  flex: 1,
  padding: 20,
})

const PhoneInput = styled(PInput)({
  marginBottom: '24px',
  [`
    &.react-tel-input .flag-dropdown.open .selected-flag,
    &.react-tel-input .selected-flag:hover,
    &.react-tel-input .selected-flag:focus
  `]: {
    backgroundColor: 'transparent',
  },
  [`
    &.react-tel-input .country-list .country.highlight,
    &.react-tel-input .country-list .country:hover
  `]: {
    backgroundColor: '#1c1c1c',
  },
})

const Error = styled.div({
  color: 'red',
  fontSize: '0.75rem',
  marginTop: '0.5rem',
  fontStyle: 'italic',
  textAlign: 'center',
})

export const Login = () => {
  const [phone, setPhone] = useState<string>('')
  const [pin, setPin] = useState<string>('')
  const [countryDialCode, setCountryDialCode] = useState<string>('')
  const [shouldVerify, setShouldVerify] = useState<boolean>(false)
  const [phoneError, setPhoneError] = useState<string>('')
  const [pinError, setPinError] = useState<string>('')

  const { setCurrentUser } = useContext(UserContext)
  const navigate = useNavigate()
  const [, setCookie] = useCookies(['access_token'])

  const handlePhoneChange = (val: string, data: CountryData) => {
    setCountryDialCode(data.dialCode)
    setPhone(val)
  }

  const handlePhoneInputMount = (_val: string, data: CountryData) => {
    setCountryDialCode(data.dialCode)
  }

  const handleInput = useCallback(
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (value.length < countryDialCode.length + 1) return
      const phoneNumber = value.replace(/\D/g, '')
      if (
        phoneNumber.substring(0, countryDialCode.length) !== countryDialCode
      ) {
        setPhone(`${countryDialCode}${phoneNumber}`)
      }
    },
    [countryDialCode],
  )

  const handlePinInput = (e: React.ChangeEvent<HTMLInputElement>) =>
    setPin(e.target.value)

  const handleSubmit = useCallback(async () => {
    setPhoneError('')
    try {
      await getCode(phone)
      setShouldVerify(true)
    } catch (err) {
      if ((err as any).response?.data?.statusCode === 404) {
        const errMessage = 'User does not exist.'
        Mixpanel.track('Error Message Displayed', {
          error_id: errMessage,
          error_text: errMessage,
          error_description: errMessage,
          error_context: 'Login no account with input phone number',
          is_displayed: true,
        })
        setPhoneError(errMessage)
      }
    }
  }, [phone])

  const handleValidateCode = useCallback(async () => {
    setPinError('')
    try {
      const { data } = await verifyCode(phone, pin)
      const { user, accessToken } = data.data
      setCookie('access_token', accessToken)
      setCurrentUser(new User(user))
      navigate('/asks')
    } catch (err) {
      const errMessage = 'Invalid Pin.'
      Mixpanel.track('Error Message Displayed', {
        error_id: errMessage,
        error_text: errMessage,
        error_description: errMessage,
        error_context: 'Login invalid phone pin',
        is_displayed: true,
      })
      setPinError(errMessage)
    }
  }, [phone, pin, setCurrentUser, navigate, setCookie])

  const handleBack = useCallback(() => {
    setPhone('')
    setPin('')
    setPinError('')
    setShouldVerify(false)
  }, [])

  useEffect(() => {
    Mixpanel.track('Onboarding Phone Input Viewed')
  }, [])

  if (shouldVerify) {
    return (
      <Main>
        <div onClick={handleBack} style={{ cursor: 'pointer' }}>
          &#10094; Back
        </div>
        <h4>Verify your number</h4>
        <TextField
          fullWidth
          type="text"
          inputProps={{ maxLength: 4 }}
          placeholder="Verification code"
          value={pin}
          onChange={handlePinInput}
        />
        <Button onClick={handleValidateCode}>Go</Button>
        {pinError ? <Error>{pinError}</Error> : null}
      </Main>
    )
  }

  return (
    <Main>
      <h4 style={{ marginBottom: '0.88rem' }}>Sign-in or create an account.</h4>
      <p style={{ marginBottom: '2.12rem' }}>Enter your phone number.</p>
      <PhoneInput
        value={phone}
        inputProps={{
          name: 'phoneInput',
          onInput: handleInput,
        }}
        onChange={handlePhoneChange}
        onMount={handlePhoneInputMount}
        specialLabel=""
        country={'us'}
        onlyCountries={BETA_ALLOWED_COUNTRIES}
        countryCodeEditable={false}
        dropdownStyle={{
          background: '#1c1c1c',
        }}
        buttonStyle={{
          background: 'transparent',
          border: 'none',
        }}
        inputStyle={{
          color: '#ffffff',
          width: '100%',
          height: '48px',
          borderRadius: '4px',
          backgroundColor: 'transparent',
          border: '1px solid #48484a',
          fontSize: '1rem',
        }}
      />
      <Button onClick={handleSubmit}>Next</Button>
      {phoneError ? <Error>{phoneError}</Error> : null}
    </Main>
  )
}
