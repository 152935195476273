export enum Palette {
  Orange = '#FF582B',
  Gray = 'rgba(255, 255, 255, 0.7)',
  DarkGray = 'rgba(255, 255, 255, 0.23)',
  GrayBox = '#121212',
  GrayBoxMid = '#1e1e1e',
  GrayBoxLight = '#2f2f2f',
  Black = '#000000',
  White = '#FFFFFF',
  Yellow = '#FFD60A',
  LightBlue = '#90CAF9',
}
