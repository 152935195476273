import styled from '@emotion/styled'
import { Alert, ClickAwayListener, Snackbar } from '@mui/material'
import { createPlace, getAsk, getAskRecs, postAskRec, searchPlaces } from 'api'
import { Button } from 'components/Button'
import { ISuggestion, InputSuggestions } from 'components/InputSuggestions'
import { TextField } from 'components/TextField'
import { LocationPin } from 'components/icons/LocationPin'
import Loading from 'components/Loading'
import UserContext from 'context/UserContext'
import { useDebounce } from 'hooks/useDebounce'
import { groupBy } from 'lodash'
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'
import { Palette } from 'styles/palette'
import Ask from 'models/ask'
import { Mixpanel } from 'lib/mixpanel'
import AppContext from 'context/AppContext'
import { Helmet } from 'react-helmet'

const Main = styled.div({})
const Header = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '4.5rem',
  marginBottom: '0.7rem',
})

interface IUserPictureProps {
  picture?: string
}
const UserPicture = styled.div(
  {
    borderRadius: '50%',
    width: '2.5rem',
    height: '2.5rem',
    marginRight: '1rem',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  (props: IUserPictureProps) => ({
    backgroundImage: `url(${props.picture})`,
  }),
)
const Share = styled.div({
  flex: '0 1 auto',
})
const Subtitle = styled.div({
  color: Palette.Gray,
  textTransform: 'uppercase',
  fontSize: '0.75rem',
})
const Title = styled.h3({
  margin: 0,
})
const Location = styled.div({
  color: Palette.Gray,
  marginBottom: '2.5rem',
})
const Form = styled.div({ marginBottom: '1.94rem' })
const NoRecs = styled.div({
  display: 'flex',
  flexFlow: 'colum nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2.8rem',
  background: Palette.GrayBox,
  textAlign: 'center',
  color: Palette.Gray,
})
const Recommendations = styled.div({
  display: 'flex',
  flexFlow: 'column nowrap',
})
const Recommendation = styled.div({
  display: 'flex',
  flexFlow: 'column nowrap',
  marginBottom: '1.5rem',
})
const RecommendationBody = styled.div({
  background: Palette.GrayBox,
  color: Palette.Gray,
  padding: '1rem',
  '& h6': {
    margin: 0,
    marginBottom: '0.4rem',
    color: Palette.White,
  },
})
const RecommendationSubtitle = styled.div({
  fontSize: '0.875rem',
  color: Palette.Gray,
  marginBottom: '0.4rem',
})
const RecommendationTips = styled.div({
  background: Palette.Black,
  padding: '1rem 1.25rem',
})
const RecommendationTip = styled.div({
  marginBottom: '0.5rem',
  color: Palette.White,
  fontSize: '0.75rem',
})

interface IPlaceSuggestion {
  name: string
  location: string
  googlePlaceId: string
}

export const AskPage = () => {
  const [place, setPlace] = useState('')
  const [placeId, setPlaceId] = useState<number>(0)
  const [placeName, setPlaceName] = useState<string>('')
  const [placeLocality, setPlaceLocality] = useState<string>('')
  const [tip, setTip] = useState('')
  const [suggestions, setSuggestions] = useState<IPlaceSuggestion[]>([])
  const [showSuggestionsBox, setShowSuggestionsBox] = useState(false)
  const [alertText, setAlertText] = useState('')
  const [posting, setPosting] = useState<boolean>(false)
  const [searching, setSearching] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const { waitlistCta, setWaitlistModal } = useContext(AppContext)
  const { guestId, currentUser } = useContext(UserContext)
  const inputRef = useRef<HTMLInputElement>(null)

  const { id: askId } = useParams()
  const [urlQueryParams] = useSearchParams()

  const { isLoading, error, data } = useQuery(
    `getAsk-${askId}`,
    () => getAsk(askId as string),
    {
      enabled: !!askId,
    },
  )
  const ask = useMemo(() => {
    if (data?.data) return new Ask(data.data)
  }, [data])

  const { isLoading: isLoadingRecs, data: dataRecs } = useQuery(
    `getRecs-${askId}`,
    () => getAskRecs(ask?.id as string),
    { enabled: !!ask?.id },
  )
  const { mutate } = useMutation(
    'postRec',
    async () => {
      if (!ask) return
      setPosting(true)
      try {
        const res = await postAskRec(ask.id, placeId, tip || undefined, guestId)
        if (res.status === 201) {
          setAlertText('Place Suggested')
          Mixpanel.track('Suggestion Completed', {
            ask_name: ask.body,
            ask_location: ask.location,
            ask_url: ask.getUrl(),
            ask_id: ask.id,
            ask_publisher_id: ask.author.id,
            ask_publisher_username: ask.author.username,
            rec_name: placeName,
            rec_location: placeLocality,
            rec_tip: tip,
          })
          if (!currentUser && !waitlistCta) setWaitlistModal(true)
        }
        setPosting(false)
      } catch (err) {
        console.error(err)
        setPosting(false)
      }
    },
    { onSuccess: () => queryClient.invalidateQueries([`getRecs-${askId}`]) },
  )

  const recs = useMemo(() => {
    const rawRecs = dataRecs?.data ?? []
    return Object.values(groupBy(rawRecs, 'placeId'))
      .sort((a, b) => b.length - a.length)
      .map((asks) => ({
        place: asks[0].place,
        tips: asks.map((ask) => ask.tip).filter((tip) => tip),
        count: asks.length,
      }))
  }, [dataRecs])

  const debouncedSearch = useDebounce(async () => {
    if (!place) {
      setSuggestions([])
      setSearching(false)
      return
    }
    const { data } = await searchPlaces(
      place,
      ask?.latitude,
      ask?.longitude,
      urlQueryParams.get('textsearch') === '1',
    )
    setSuggestions(data?.data?.results ?? [])
    setSearching(false)
  })

  const handlePlaceInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearching(true)
    setPlace(e.target.value)
    debouncedSearch()
  }

  const handleTipInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setTip(e.target.value)

  const handleSuggestionClick =
    ({ value, id }: ISuggestion) =>
    async (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      try {
        const { data } = await createPlace(id as string)
        const rawPlace = data?.data
        if (rawPlace) {
          setPlaceId(rawPlace.id)
          setPlaceName(rawPlace.name)
          setPlaceLocality(rawPlace.locality)
        }
        setShowSuggestionsBox(false)
        setPlace(value)
        setSuggestions([])
      } catch (err) {
        console.error(err)
      }
    }

  const handleCloseSuggestionsBox = useCallback(
    () => setShowSuggestionsBox(false),
    [setShowSuggestionsBox],
  )

  const handleSuggest = useCallback(async () => {
    if (!ask?.id || !placeId) return
    mutate()
    setPlace('')
    setPlaceId(0)
    setPlaceName('')
    setPlaceLocality('')
    setTip('')
  }, [mutate, ask, placeId])

  const handleOpenSuggestionsBox = useCallback(
    () => setShowSuggestionsBox(true),
    [setShowSuggestionsBox],
  )

  const handleShare = useCallback(() => {
    if (!ask) return
    Mixpanel.track('Share Ask Button Clicked', {
      ask_name: ask.body,
      ask_location: ask.location,
      ask_url: ask.getUrl(),
      ask_id: ask.id,
    })
    navigator.clipboard.writeText(window.location.href)
    setAlertText('Link Copied to Clipboard')
  }, [setAlertText, ask])

  useEffect(() => {
    if (!ask) return
    Mixpanel.track('Suggestion Page Viewed', {
      ask_name: ask.body,
      ask_location: ask.location,
      ask_url: ask.getUrl(),
      ask_id: ask.id,
      ask_publisher_id: ask.author.id,
      ask_publisher_username: ask.author.username,
      number_of_unique_recs: ask.recCount,
    })
  }, [ask])

  const waitlistTimerRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    waitlistTimerRef.current = setTimeout(() => {
      setWaitlistModal(true)
    }, 60000)
    return () => {
      if (waitlistTimerRef.current) clearTimeout(waitlistTimerRef.current)
    }
  }, [])

  useEffect(() => {
    if (waitlistTimerRef.current && (currentUser || waitlistCta)) {
      clearTimeout(waitlistTimerRef.current)
    }
  }, [waitlistTimerRef, waitlistCta, currentUser])

  if (isLoading) return <Loading />
  if (error) return <div>error: ${JSON.stringify(error)}</div>
  if (!ask || !recs) return null
  const { author } = ask
  const title = `‘${ask.body} in ${ask.location}’ by ${ask.author.name} | It’s Good Labs`
  return (
    <Main>
      <Helmet>
        <title>{title}</title>
        <meta property="og:url" content={window.location.href} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_CDN_URL}/labs/ask/ask_rec_share.png`}
        />
        <meta
          property="og:image:secure_url"
          content={`${process.env.REACT_APP_CDN_URL}/labs/ask/ask_rec_share.png`}
        />
        <meta property="og:image:type" content="image/png" />
      </Helmet>
      <Header>
        <div
          style={{
            display: 'flex',
            flexFlow: 'row nowrap',
            alignItems: 'center',
          }}
        >
          <UserPicture picture={author.picture} />
          <div>{author.name}</div>
        </div>
        <Share>
          <Button outlined onClick={handleShare}>
            Share
          </Button>
        </Share>
      </Header>
      <Subtitle>I need recs for…</Subtitle>
      <Title>{ask.body}</Title>
      <Location>in {ask.location}</Location>
      <Form>
        <ClickAwayListener onClickAway={handleCloseSuggestionsBox}>
          <div>
            <TextField
              inputRef={inputRef}
              type="text"
              fullWidth
              label="Name of place"
              value={place}
              onChange={handlePlaceInput}
              onFocus={handleOpenSuggestionsBox}
              showSpinner={searching}
            />
            {inputRef.current ? (
              <InputSuggestions
                inputRef={inputRef.current}
                open={showSuggestionsBox && !!suggestions.length}
                suggestions={suggestions.map(
                  ({ name, location, googlePlaceId }) => ({
                    id: googlePlaceId,
                    value: name,
                    description: location,
                    icon: <LocationPin />,
                  }),
                )}
                onSelect={handleSuggestionClick}
              />
            ) : null}
          </div>
        </ClickAwayListener>
        <TextField
          type="text"
          fullWidth
          label="Add a tip"
          helperText="E.g. “You should ask for the Good Good Roll…it’s only on the secret menu and it’s AMAZING!”"
          value={tip}
          onChange={handleTipInput}
        />
        {posting ? (
          <Loading />
        ) : (
          <Button onClick={handleSuggest}>Tell ‘em What’s Good</Button>
        )}
      </Form>
      {isLoadingRecs ? <Loading /> : null}
      {recs.length ? (
        <Recommendations>
          {recs.map(({ place, tips, count }) => (
            <Recommendation key={place.id}>
              <RecommendationBody>
                <RecommendationSubtitle>
                  {count} {count > 1 ? 'people' : 'person'} said It's Good
                </RecommendationSubtitle>
                <h6>{place.name}</h6>
                <div>{place.location}</div>
              </RecommendationBody>
              {tips.length ? (
                <RecommendationTips>
                  {tips.map((tip, idx) => (
                    <RecommendationTip key={idx}>Tip: {tip}</RecommendationTip>
                  ))}
                </RecommendationTips>
              ) : null}
            </Recommendation>
          ))}
        </Recommendations>
      ) : (
        <NoRecs>
          There are no recs yet.
          <br />
          Be the first to suggest What’s Good!
        </NoRecs>
      )}
      <Snackbar
        open={!!alertText}
        autoHideDuration={6000}
        onClose={() => setAlertText('')}
      >
        <Alert
          variant="filled"
          severity="success"
          sx={{
            width: '100%',
            background: Palette.White,
            color: Palette.Black,
          }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </Main>
  )
}
