import type User from 'models/user'
import { createContext } from 'react'

export interface UserContextData {
  guestId?: string
  currentUser?: User | null
  setCurrentUser: (user: User) => void
}

const UserContext = createContext<UserContextData>({
  guestId: undefined,
  currentUser: undefined,
  setCurrentUser: () => null,
})

export default UserContext
