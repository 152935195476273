import styled from '@emotion/styled'
import { ClickAwayListener } from '@mui/material'
import { postAsk, searchLocations } from 'api'
import { getLatLong } from 'api/google'
import { Button } from 'components/Button'
import { ISuggestion, InputSuggestions } from 'components/InputSuggestions'
import Loading from 'components/Loading'
import { TextField } from 'components/TextField'
import { LocationPin } from 'components/icons/LocationPin'
import UserContext from 'context/UserContext'
import { useDebounce } from 'hooks/useDebounce'
import { Mixpanel } from 'lib/mixpanel'
import Ask from 'models/ask'
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

const Main = styled.div({})
const Form = styled.div({})

interface ILocationSuggestion {
  name: string
  location: string
  googlePlaceId: string
}

export const AskCreate = () => {
  const [body, setBody] = useState<string>('')
  const [location, setLocation] = useState<string>('')
  const [suggestions, setSuggestions] = useState<ILocationSuggestion[]>([])
  const [showSuggestionsBox, setShowSuggestionsBox] = useState(false)
  const [searching, setSearching] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)

  const debouncedSearch = useDebounce(async () => {
    if (!location) {
      setSuggestions([])
      setSearching(false)
      return
    }
    const { data } = await searchLocations(location)
    setSuggestions(data?.data?.results ?? [])
    setSearching(false)
  })

  const handleBodyInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => setBody(e.target.value)

  const handleLocationInput = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSearching(true)
    setLocation(e.target.value)
    debouncedSearch()
  }

  const handlePublish = useCallback(async () => {
    if (!body || !location) return
    const { data: coords } = await getLatLong(location)
    const { data: askRaw } = await postAsk(
      body,
      location,
      coords.lat,
      coords.lng,
    )
    if (askRaw.id) {
      const ask = new Ask(askRaw)
      Mixpanel.track('Ask Published', {
        ask_name: ask.body,
        ask_location: ask.location,
        ask_url: ask.getUrl(),
        ask_id: ask.id,
      })
      navigate(`/ask/${ask.id}`)
    }
  }, [body, location, navigate])

  const handleSuggestionClick =
    ({ value, description }: ISuggestion) =>
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      setShowSuggestionsBox(false)
      setLocation(`${value}${description ? `, ${description}` : ''}`)
      setSuggestions([])
    }

  const handleCloseSuggestionsBox = useCallback(
    () => setShowSuggestionsBox(false),
    [setShowSuggestionsBox],
  )

  const handleOpenSuggestionsBox = useCallback(
    () => setShowSuggestionsBox(true),
    [setShowSuggestionsBox],
  )

  useEffect(() => {
    if (currentUser === null) navigate('/login')
  }, [currentUser, navigate])

  if (currentUser === undefined) return <Loading />

  return (
    <Main>
      <h4>Find What’s Good.</h4>
      <Form>
        <TextField
          type="text"
          fullWidth
          label="I need recs for…"
          helperText="E.g. Secret Menu gems in NYC, Best Candle Lit Date Night Spots in Los Angeles"
          value={body}
          onChange={handleBodyInput}
        />
        <ClickAwayListener onClickAway={handleCloseSuggestionsBox}>
          <div>
            <TextField
              inputRef={inputRef}
              type="text"
              fullWidth
              label="Location"
              helperText="City, Neighborhood, or Region"
              value={location}
              onChange={handleLocationInput}
              onFocus={handleOpenSuggestionsBox}
              showSpinner={searching}
            />
            {inputRef.current ? (
              <InputSuggestions
                inputRef={inputRef.current}
                open={showSuggestionsBox && !!suggestions.length}
                suggestions={suggestions.map(
                  ({ name, location, googlePlaceId }) => ({
                    id: googlePlaceId,
                    value: name,
                    description: location,
                    icon: <LocationPin />,
                  }),
                )}
                onSelect={handleSuggestionClick}
              />
            ) : null}
          </div>
        </ClickAwayListener>
        <Button onClick={handlePublish}>Find Out What’s Good</Button>
      </Form>
    </Main>
  )
}
