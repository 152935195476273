import styled from '@emotion/styled'
import Ask from 'models/ask'
import { Palette } from 'styles/palette'
import { Button } from './Button'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Snackbar } from '@mui/material'

const Main = styled.div({
  display: 'flex',
  flexFlow: 'column nowrap',
  marginBottom: '1.5rem',
  background: Palette.GrayBoxMid,
})

const Info = styled.div({ padding: '1rem' })

const Action = styled.div({
  display: 'flex',
  padding: '0 1rem 1rem',
  '& button': {
    flex: '0 1 auto',
    width: 'auto',
    whiteSpace: 'nowrap',
    padding: '0',
    marginRight: '1.8rem',
    minWidth: 0,
    boxShadow: 'none !important',
  },
})

interface IProps {
  ask: Ask
}
export const AskCard = ({ ask: { id, body, location, recCount } }: IProps) => {
  const [alertText, setAlertText] = useState('')

  const navigate = useNavigate()

  const handleView = useCallback(
    (askId: string) => () => navigate(`/ask/${askId}`),
    [navigate],
  )

  const handleShare = useCallback(
    (askId: string) => () => {
      const url = `${window.location.origin}/ask/${askId}`
      navigator.clipboard.writeText(url)
      setAlertText('Link Copied to Clipboard')
    },
    [setAlertText],
  )

  return (
    <Main>
      <Info>
        <h6 style={{ margin: '0 0 0.5rem 0' }}>{body}</h6>
        <div style={{ color: Palette.Gray }}>{location}</div>
      </Info>
      <Action>
        <Button
          secondary={true}
          sx={{ width: 'auto' }}
          onClick={handleView(id)}
        >
          View{recCount > 0 ? ` ${recCount} Rec` : ''}
          {recCount > 1 ? 's' : ''}
        </Button>
        <Button secondary={true} onClick={handleShare(id)}>
          Share
        </Button>
      </Action>
      <Snackbar
        open={!!alertText}
        autoHideDuration={6000}
        onClose={() => setAlertText('')}
      >
        <Alert
          variant="filled"
          severity="success"
          sx={{
            width: '100%',
            background: Palette.White,
            color: Palette.Black,
          }}
        >
          {alertText}
        </Alert>
      </Snackbar>
    </Main>
  )
}
