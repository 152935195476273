import { createContext } from 'react'

export enum WaitlistCta {
  Typeform = 'typeform',
  Dismissed = 'dismissed',
}
export interface AppContextData {
  waitlistModal: boolean
  setWaitlistModal: (show: boolean) => void
  waitlistCta: WaitlistCta | undefined
  setWaitlistCta: (cta: WaitlistCta) => void
}

const AppContext = createContext<AppContextData>({
  waitlistModal: false,
  setWaitlistModal: () => null,
  waitlistCta: undefined,
  setWaitlistCta: () => null,
})

export default AppContext
