import User, { type IUserData } from 'models/user'

export interface IAskData {
  id: string
  userId: number
  author?: IUserData
  body: string
  location: string
  latitude: number
  longitude: number
  createdAt: string
  modifiedAt: string
  recCount: number
}

export default class Ask {
  id: string
  userId: number
  author: User
  body: string
  location: string
  latitude: number
  longitude: number
  createdAt: Date
  modifiedAt: Date
  recCount: number
  constructor({
    id,
    userId,
    author,
    body,
    location,
    latitude,
    longitude,
    createdAt,
    modifiedAt,
    recCount,
  }: IAskData) {
    this.id = id
    this.userId = userId
    this.author = author ? new User(author) : ({} as User)
    this.body = body
    this.location = location
    this.latitude = latitude
    this.longitude = longitude
    this.createdAt = new Date(createdAt)
    this.modifiedAt = new Date(modifiedAt)
    this.recCount = recCount
  }
  getUrl() {
    return `${window.location.origin}/ask/${this.id}`
  }
}
