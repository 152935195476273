import { CircularProgress } from '@mui/material'
import styled from '@emotion/styled'
import { Palette } from 'styles/palette'

const Main = styled.div(
  {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .MuiCircularProgress-root': {
      color: Palette.Orange,
    },
  },
  (props: IProps) => ({
    height: props.height || 36,
  }),
)

interface IProps {
  height?: number
}

const Loading = ({ height }: IProps) => (
  <Main height={height}>
    <CircularProgress />
  </Main>
)

export default Loading
