import styled from '@emotion/styled'
import { Outlet } from 'react-router-dom'
import { APIProvider } from '@vis.gl/react-google-maps'

const Main = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexFlow: 'column nowrap',
  padding: 20,
  maxWidth: 800,
  margin: 'auto',
})

export const Root = () => (
  <Main>
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY as string}>
      <Outlet />
    </APIProvider>
  </Main>
)
