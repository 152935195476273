import styled from '@emotion/styled'
import { Popper } from '@mui/material'
import { MouseEventHandler, ReactNode } from 'react'
import { Palette } from 'styles/palette'

const SuggestionsWrapper = styled.div({
  display: 'flex',
  maxHeight: '12rem',
  background: Palette.GrayBoxLight,
  padding: '1.12rem 1.12rem 1.12rem 0.38rem',
})
const SuggestionsBox = styled.div({
  display: 'flex',
  flexFlow: 'column nowrap',
  flex: 1,
  overflow: 'auto',
})

const SuggestionWrapper = styled.div({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  marginBottom: '0.62rem',
  '& svg': {
    height: 24,
    width: 24,
  },
})

const Suggestion = styled.div({
  '& span': {
    fontSize: '0.75rem',
    color: Palette.Gray,
  },
})

const SuggestionIcon = styled.div({
  marginRight: '0.38rem',
})

export interface ISuggestion {
  id: string | number
  value: string
  description?: string
  icon?: ReactNode
}
interface IProps {
  inputRef: HTMLElement
  open: boolean
  suggestions: ISuggestion[]
  onSelect: (sug: ISuggestion) => MouseEventHandler<HTMLDivElement>
}
export const InputSuggestions = ({
  inputRef,
  open,
  suggestions,
  onSelect,
}: IProps) => {
  return (
    <>
      {inputRef ? (
        <Popper
          sx={{ zIndex: 1, width: inputRef.clientWidth }}
          open={open}
          anchorEl={inputRef}
        >
          <SuggestionsWrapper>
            <SuggestionsBox>
              {suggestions.map((sug) => (
                <SuggestionWrapper key={sug.id}>
                  {sug.icon ? (
                    <SuggestionIcon>{sug.icon}</SuggestionIcon>
                  ) : null}
                  <Suggestion onClick={onSelect(sug)}>
                    {sug.value}
                    {sug.description ? <span> {sug.description}</span> : null}
                  </Suggestion>
                </SuggestionWrapper>
              ))}
            </SuggestionsBox>
          </SuggestionsWrapper>
        </Popper>
      ) : null}
    </>
  )
}
