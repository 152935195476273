import { Dialog, DialogContent } from '@mui/material'
import { Button } from './Button'
import { useCallback, useContext } from 'react'
import AppContext, { WaitlistCta } from 'context/AppContext'
import { Mixpanel } from 'lib/mixpanel'

const WAITLIST_TYPEFORM_URL = 'https://itsgood.typeform.com/waitlist'

export const WaitlistModal = () => {
  const { setWaitlistCta, setWaitlistModal } = useContext(AppContext)

  const handleGoToForm = useCallback(() => {
    setWaitlistCta(WaitlistCta.Typeform)
    setWaitlistModal(false)
    localStorage.setItem('visitedWaitlistForm', '1')
    Mixpanel.track('Join Waitlist Button Click', {
      Context: 'Ask for Recs',
    })
    window.open(WAITLIST_TYPEFORM_URL, '_blank')
  }, [setWaitlistCta, setWaitlistModal])

  const handleDismiss = useCallback(() => {
    setWaitlistCta(WaitlistCta.Dismissed)
    setWaitlistModal(false)
  }, [setWaitlistCta, setWaitlistModal])

  return (
    <Dialog
      open={true}
      PaperProps={{
        style: { backgroundColor: '#121212', color: 'white', maxWidth: 480 },
      }}
    >
      <DialogContent>
        <h5 style={{ margin: 0 }}>
          Like what we are cookin’ up? There is more to come!
        </h5>
        <p className="body-2" style={{ marginBottom: '2.38rem' }}>
          We’re working on a few projects just like this. If you’re a food fan,
          join our waitlist to be the first to try out It’s Good.
        </p>
        <Button onClick={handleGoToForm} sx={{ marginBottom: '1rem' }}>
          Sign me up!
        </Button>
        <Button onClick={handleDismiss} secondary>
          No, thanks.
        </Button>
      </DialogContent>
    </Dialog>
  )
}
