import styled from '@emotion/styled'
import { type ButtonProps, Button as MuiButton } from '@mui/material'
import { Palette } from 'styles/palette'

const StyledButton = styled(MuiButton)({
  width: '100%',
  boxShadow: 'none',
})

interface IProps extends ButtonProps {
  secondary?: boolean
  outlined?: boolean
}

export const Button = ({ children, secondary, outlined, ...props }: IProps) => (
  <StyledButton
    variant={outlined ? 'outlined' : 'contained'}
    style={{
      backgroundColor: outlined || secondary ? 'transparent' : Palette.Orange,
      color: secondary || outlined ? Palette.Orange : Palette.White,
      borderColor: Palette.Orange,
    }}
    {...props}
  >
    {children}
  </StyledButton>
)
