import styled from '@emotion/styled'
import { listAsks } from 'api'
import { AskCard } from 'components/AskCard'
import { Button } from 'components/Button'
import Loading from 'components/Loading'
import UserContext from 'context/UserContext'
import { Mixpanel } from 'lib/mixpanel'
import Ask, { IAskData } from 'models/ask'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

const Main = styled.div({})
export const Asks = () => {
  const navigate = useNavigate()
  const { currentUser } = useContext(UserContext)
  const { isLoading, error, data } = useQuery(
    `getAsks-${currentUser?.id}`,
    () => listAsks(),
    {
      enabled: !!currentUser?.id,
    },
  )

  const asks: Ask[] = useMemo(() => {
    if (data?.data) return data?.data.map((ask: IAskData) => new Ask(ask))
  }, [data])

  const handleCreate = useCallback(() => {
    Mixpanel.track('Create New Ask Button Clicked')
    navigate('/ask')
  }, [navigate])

  useEffect(() => {
    if (currentUser === null) navigate('/login')
  }, [currentUser, navigate])

  useEffect(() => {
    Mixpanel.track('List Of Asks Page Viewed')
  }, [])

  if (isLoading) return <Loading />

  if (!asks) return null

  return (
    <Main>
      <Button sx={{ marginBottom: '1.5rem' }} onClick={handleCreate}>
        Ask “What’s Good?”
      </Button>
      {asks.length
        ? asks.map((ask) => <AskCard key={ask.id} ask={ask} />)
        : null}
    </Main>
  )
}
