import styled from '@emotion/styled'
import {
  CircularProgress,
  TextField as MuiTextField,
  TextFieldProps,
} from '@mui/material'
import { Palette } from 'styles/palette'

const TextFieldStyled = styled(MuiTextField)({
  marginBottom: '1.56rem',
  '& label.MuiFormLabel-root': {
    color: Palette.Gray,
  },
  '& .MuiInputBase-root': {
    color: Palette.White,
  },
  '& .MuiFormHelperText-root': {
    color: Palette.Gray,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: Palette.DarkGray,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: Palette.DarkGray + '!important',
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: Palette.LightBlue + '!important',
  },
})

interface IProps {
  showSpinner?: boolean
}
export const TextField = ({
  showSpinner,
  ...props
}: TextFieldProps & IProps) => (
  <>
    <TextFieldStyled {...props} autoComplete="off" />
    {showSpinner && (
      <span style={{ position: 'absolute', marginLeft: -52, marginTop: 8 }}>
        <CircularProgress sx={{ color: Palette.Gray }} />
      </span>
    )}
  </>
)
