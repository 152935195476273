import styled from '@emotion/styled'
import { useCallback, useContext } from 'react'
import UserContext from 'context/UserContext'
import { Link, useNavigate } from 'react-router-dom'
import LogoVertical from './LogoVertical'

const ITSGOOD_WEBSITE = 'https://www.itsgood.life'

const Main = styled.div({
  height: '52px',
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 16px 0',
  borderBottom: '1px solid #48484A',
})

const UserFullName = styled.div({
  fontSize: '0.875rem',
})

const UserPicture = styled.div(
  {
    borderRadius: '50%',
    width: '1.625rem',
    height: '1.625rem',
    marginLeft: '0.75rem',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  (props: IUserPictureProps) => ({
    backgroundImage: `url(${props.picture})`,
  }),
)

interface IUserPictureProps {
  picture?: string
}

const TopNav = () => {
  const { currentUser } = useContext(UserContext)
  const navigate = useNavigate()

  const handleViewAsks = useCallback(() => navigate('/asks'), [navigate])

  return (
    <Main>
      {currentUser ? (
        <LogoVertical />
      ) : (
        <Link to={ITSGOOD_WEBSITE} target="_blank">
          <LogoVertical />
        </Link>
      )}
      {currentUser ? (
        <div
          onClick={handleViewAsks}
          style={{ display: 'flex', flexFlow: 'row nowrap', cursor: 'pointer' }}
        >
          <UserFullName>{currentUser.name}</UserFullName>
          <UserPicture picture={currentUser.picture} />
        </div>
      ) : null}
    </Main>
  )
}

export default TopNav
