import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom/client'
import 'index.css'
import reportWebVitals from 'reportWebVitals'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Root } from 'routes/root'
import styled from '@emotion/styled'
import TopNav from 'components/TopNav'
import { AskPage } from 'routes/ask'
import { Asks } from 'routes/asks'
import { AskCreate } from 'routes/ask-create'
import { QueryClient, QueryClientProvider } from 'react-query'
import UserContext from 'context/UserContext'
import { v4 as uuid } from 'uuid'
import { Login } from 'routes/login'
import User from 'models/user'
import { useCookies } from 'react-cookie'
import { getMe } from 'api/user'
import api from 'api/base'
import { Mixpanel } from 'lib/mixpanel'
import AppContext, { WaitlistCta } from 'context/AppContext'
import { WaitlistModal } from 'components/WaitlistModal'
import { Helmet } from 'react-helmet'
import { MapSearch } from 'routes/map-search'

const Wrapper = styled.div({
  flex: 1,
  height: '100%',
  overflow: 'overlay',
  position: 'relative',
})

const Content = styled.div({
  minHeight: '0px',
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

const queryClient = new QueryClient()

const App = ({ children }: PropsWithChildren) => {
  const [currentUser, setCurrentUser] = useState<User | undefined | null>(
    undefined,
  )
  const [waitlistModal, setWaitlistModal] = useState<boolean>(false)
  const [waitlistCta, setWaitlistCta] = useState<WaitlistCta | undefined>(
    undefined,
  )

  const [cookies] = useCookies(['access_token'])
  const accessToken = useMemo(() => cookies['access_token'], [cookies])

  useEffect(() => {
    async function initialLogin() {
      try {
        const { data } = await getMe()
        if (data) setCurrentUser(new User(data.data))
      } catch (err) {
        setCurrentUser(null)
      }
    }
    if (accessToken) {
      api.defaults.headers.common['access_token'] = accessToken
      initialLogin()
    } else {
      setCurrentUser(null)
    }
  }, [accessToken])

  const guestId = useMemo(() => {
    let id = localStorage.getItem('guestId')
    if (!id) {
      id = uuid()
      localStorage.setItem('guestId', id)
    }
    return id
  }, [])

  useEffect(() => {
    if (currentUser) {
      Mixpanel.identify(currentUser.id.toString())
    }
  }, [currentUser])

  useEffect(() => {
    const visitedWaitlistform = localStorage.getItem('visitedWaitlistForm')
    if (visitedWaitlistform) setWaitlistCta(WaitlistCta.Typeform)
  }, [])

  const title = 'It’s Good Labs'
  return (
    <>
      <AppContext.Provider
        value={{ waitlistModal, setWaitlistModal, waitlistCta, setWaitlistCta }}
      >
        <UserContext.Provider value={{ guestId, currentUser, setCurrentUser }}>
          <>
            <Helmet>
              <title>{title}</title>
              <meta property="og:url" content={window.location.href} />
              <meta property="og:title" content={title} />
              <meta property="og:type" content="website" />
              <meta
                property="og:image"
                content={`${process.env.REACT_APP_CDN_URL}/labs/ask/ask_rec_share.png`}
              />
              <meta
                property="og:image:secure_url"
                content={`${process.env.REACT_APP_CDN_URL}/labs/ask/ask_rec_share.png`}
              />
              <meta property="og:image:type" content="image/png" />
            </Helmet>
            {children}
            {waitlistModal ? <WaitlistModal /> : null}
          </>
        </UserContext.Provider>
      </AppContext.Provider>
    </>
  )
}

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route
              path="/"
              element={
                <>
                  <TopNav />
                  <Wrapper>
                    <Content>
                      <Root></Root>
                    </Content>
                  </Wrapper>
                </>
              }
            >
              <Route path="ask">
                <Route index element={<AskCreate />} />
                <Route path=":id" element={<AskPage />} />
              </Route>
              <Route path="asks" element={<Asks />} />
              <Route path="map-search" element={<MapSearch />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </App>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
